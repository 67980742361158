*
{
    margin: 0;
    padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@0;1&family=Montserrat:ital,wght@0,400;0,600;1,400;1,600&display=swap');
/* font-family: 'Josefin Sans', sans-serif; */
/* font-family: 'Montserrat', sans-serif; */

:root
{
    --font1: 'Josefin Sans', sans-serif;
    --font2: 'Montserrat', sans-serif;

    --black: #1C1C1E;
    --white: #F7F7F2;
    --brown_100: #E6CEAC;
    --brown_200: #CDBA94;
    --brown_300: #BDA583;
    --brown_400: #A48D6A;
    --brown_500: #8B7D62;
    --brown_600: #73654A;
    --brown_700: #524839;
    --brown_800: #292418;
    --secondaryLightMode: #545456;
    --secondaryDarkMode: #AEAEB2;

    --mobileIcon: 48px;
    --tabletIcon: 56px;
    --desktopIcon: 72px;
}

html,
body
{
    overflow: hidden;
    font-size: 16px
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    cursor: grab;
}

::-webkit-scrollbar {
    display: none;
  }

/*
* Loader
*/
.loader
{
    position: fixed;
    width: 80vw;
    height: 80vh;
    padding: 10vh 10vw;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    background-color: var(--black);
    
    z-index: 2;  
    will-change: transform;
}
.loader svg
{
    width: 100%;
    fill: none;
    stroke: var(--white);
    stroke-width: 2px;
    will-change: stroke-dashoffset;
}
@keyframes dash {
    to {
      stroke-dashoffset: 0;
    }
  }
.loaderAnim
{
    animation: dash 4s ease-out 500ms infinite alternate;
    -webkit-animation: dash 4s ease-out 500ms infinite alternate;
}
.loaderAnimEnd
{
    animation: dash 4s ease-out forwards;
    -webkit-animation: dash 4s ease-out forwards;
}

.loader-text
{
    text-align: center;
    transform: translateY(-25vh);
    animation: slideIn 2s ease forwards;
}
@keyframes slideIn {
    to {
      transform: translateY(0);
    }
  }

.loader-text-title
{
    font-size: 36px;
    font-family: var(--font1);
    color: var(--white);
    font-weight: 400;
    margin-top: 2vh;
    margin-bottom: 2vh;
}
.loader-text-p
{
    font-size: 16px;
    font-family: var(--font2);
    color: var(--secondaryDarkMode);
    font-weight: 400;
    text-transform: uppercase; 
}

/*
* Interface
*/
.interface
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: 1;
    transition: .4s;
}

header
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 90vw;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 2.5vh;
    text-align: center;
}
header h1
{
    margin-bottom: 1vh;
}

h1
{
    font-size: 36px;
    font-family: var(--font1);
    font-weight: 400;
}
.interface.lightMode h1
{
    color: var(--black);
}
.interface.darkMode h1
{
    color: var(--white);
}

h2
{
    font-size: 24px;
    font-family: var(--font2);
    font-weight: 500;
}
.interface.lightMode h2
{
    color: var(--black);
}
.interface.darkMode h2
{
    color: var(--white);
}

h3
{
    font-size: 16px;
    font-family: var(--font2);
    font-weight: 600;
}
.interface.lightMode h3
{
    color: var(--black);
}
.interface.darkMode h3
{
    color: var(--white);
}

h5
{
    font-size: 16px;
    font-family: var(--font2);
    font-weight: 400;
    text-transform: uppercase;
}
.interface.lightMode h5
{
    color: var(--secondaryLightMode);
}
.interface.darkMode h5
{
    color: var(--secondaryDarkMode);
}

p
{
    font-size: 16px;
    font-family: var(--font2);
    font-weight: 400;
}
.interface.lightMode p
{
    color: var(--secondaryLightMode);
}
.interface.darkMode p
{
    color: var(--secondaryDarkMode);
}

.headerText
{
    transition: opacity .4s;
}

h1,
h2,
h3,
h5,
p
{
    cursor: default;
    user-select: none;
}

/*Switch*/
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: var(--mobileIcon);
    height: calc(var(--mobileIcon) / 1.8);
    margin-bottom: 2.5vh;
}
      
/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
      
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--black);
    -webkit-transition: .6s;
    transition: .6s;
}
      
.slider:before {
    position: absolute;
    content: "";
    height: calc(var(--mobileIcon) / 2.2);
    width: calc(var(--mobileIcon) / 2.2);
    left: calc(var(--mobileIcon) * 0.06);
    bottom: calc(var(--mobileIcon) * 0.05);
    background-color: var(--brown_100);
    -webkit-transition: .6s;
    transition: .6s;
}
      
.switch input:checked + .slider {
    background-color: var(--brown_100);
}
      
.switch input:checked + .slider:before {
    -webkit-transform: translateX(calc(var(--mobileIcon) * 0.42));
    -ms-transform: translateX(calc(var(--mobileIcon) * 0.42));
    transform: translateX(calc(var(--mobileIcon) * 0.42));
    background-color: var(--black);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
      
.slider.round:before {
    border-radius: 50%;
}

/*
* Webgl Buttons
*/
.point
{
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
}
.point.visible .label
{
    transform: scale(1);
}

.point .label
{
    transform: scale(0, 0);

    font-family: var(--font1);
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    padding: 8px 16px;

    border: none;
    border-radius: 8px 0 8px 0;

    cursor: pointer;
    transition: 0.3s;
}

.point.visible:hover .label
{
    text-decoration: underline;
    transform: scale(1.1);
}

.point.visible
{
    transform: scale(1);
}

.point.lightMode button
{
    color: var(--white);
    background-color: var(--black);
}
.point.darkMode button
{
    color: var(--black);
    background-color: var(--brown_100);
}

/*
* Desktop Interface
*/
.desktop-overlay
{
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    width: 50vw;  
    padding: 16px;  
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 110%);

    border-radius: 64px 0 64px 0;
    border: 4px solid;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s, fill 0.3s;
}
.interface.lightMode .desktop-overlay
{
    background-color: var(--white);
    border-color: var(--black);
}
.interface.darkMode .desktop-overlay
{
    background-color: var(--black);
    border-color: var(--brown_100);
}

.desktop-overlay p
{
    margin-top: 8px;
    text-align: center;
}

/* Projects */
.project-nav
{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 45vh;
    width: calc(50vw);
}
.card
{
    width: calc(50vw - 160px);
    height: calc(45vh - 32px);
    border-radius: 64px 0 64px 0;
    background-color: var(--brown_300);
    background-position: center center;
    background-size: 80%;
    background-repeat: no-repeat;
    border: 4px solid;
    cursor: pointer;
}
.interface.lightMode .card
{
    border-color: var(--black);
}
.interface.darkMode .card
{
    border-color: var(--brown_100);
}

.bullets
{
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.bullets div
{
    width: 8px;
    height: 8px;
    border-radius: 50%;
}
.bullets .active
{
    transform: scale(1.5);
    transition: transform 0.2s;
}

.interface.lightMode .bullets div
{
    background-color: var(--secondaryDarkMode);
}
.interface.darkMode .bullets div
{
    background-color: var(--secondaryLightMode);
}

.interface.lightMode .bullets .active
{
    background-color: var(--black);
}
.interface.darkMode .bullets .active
{
    background-color: var(--white);
}

.project-title,
.project-description
{
    margin: 0 16px 8px 16px;
    display: none;
}

.project-tags
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    margin: 0 16px 8px 16px;
}
/* About */
.about-container img
{
    height: 30vh;
    border-top: 4px solid;
    border-left: 4px solid;

    border-radius: 64px 0 60px 0;

    position: absolute;
    bottom: 0;
    right: 0;
    transform-origin: 100% 100%;
}
.about-container button
{
    position: absolute;
    bottom: -26px;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: 50% 50%;
}
.about-container button:hover
{
    text-decoration: underline;
    transform: translateX(-50%) scale(1.1);
}

.desktop-skills
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    padding: 5vh 0 5vh 16px;
    width: 65%;
    height: 20vh;
    gap: 8px;
}

.contact-container
{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 32px;
    padding: 32px;
    width: 30%;
}
.contact-container img
{
    width: 70%;
}

/*
* Mobile-nav
*/
.mobile-nav
{
    height: 10vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    position: fixed;

    bottom: 2.5vh;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;  

    border-radius: 24px 0 24px 0;

    transition: opacity 0.3s;
}

.mobile-nav button
{
    font-family: var(--font1);
    text-align: center;
    font-weight: 400;
    font-size: 20px;

    height: 100%;
    width: 28vw;

    border: none;
    background: none;

    cursor: pointer;
    transition: 0.3s;
}
.mobile-nav-separator
{
    width: 4px;
    height: 100%;
}

.interface.lightMode .mobile-nav button
{
    color: var(--white);
}
.interface.darkMode .mobile-nav button
{
    color: var(--black);
}
.interface.lightMode .mobile-nav
{
    background-color: var(--black);
}
.interface.darkMode .mobile-nav
{
    background-color: var(--brown_100);
}
.interface.lightMode .mobile-nav-separator
{
    background-color: var(--brown_100);
}
.interface.darkMode .mobile-nav-separator
{
    background-color: var(--black);
}

.mobile-nav :first-child
{
    border-radius: 24px 0 0 0;
}
.mobile-nav :last-child
{
    border-radius: 0 0 24px 0;
}
.interface.lightMode .mobile-nav button:hover
{
    background-color: var(--white);
    color: var(--black);
}
.interface.darkMode .mobile-nav button:hover
{
    background-color: var(--black);
    color: var(--brown_100);
}

/*
* Mobile Interface
*/
.mobile-overlay
{
    position: fixed;
    bottom: 0;
    width: 90vw;
    height: 95vh;
    border-top: 4px solid;
    overflow-y: auto;
    overflow-x: hidden;

    text-align: center;
    padding: 0 5vw 5vh 5vw;

    transform: translateY(100%);
    transition: background-color 0.3s, border-color 0.3s, color 0.3s, fill 0.3s;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.interface.lightMode .mobile-overlay
{
    background-color: var(--white);
    border-color: var(--black);
}
.interface.darkMode .mobile-overlay
{
    background-color: var(--black);
    border-color: var(--brown_100);
}

.mobile-overlay h2
{
    margin-bottom: 16px;
}
.mobile-overlay h3
{
    margin-top: 8px;
    margin-bottom: 4px;
}
.mobile-overlay-header
{
    position: sticky;
    top: -4px;
    padding: 12px 16px 12px 16px;
    margin-left: -5.5vw;
    margin-right: -5.5vw;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.interface.lightMode .mobile-overlay-header
{
    background-color: var(--white);
    border-color: var(--black);
}
.interface.darkMode .mobile-overlay-header
{
    background-color: var(--black);
    border-color: var(--brown_100);
}

.projects-list
{
    margin-top: 2vh;
}
.projects-list p
{
    margin-bottom: 32px;
}
.projectCard
{
    width: 90%;
    height: 25vh;
    background-color: var(--brown_100);
    border-radius: 32px 0 32px 0;
    background-position: center center;
    background-size: 90%;
    background-repeat: no-repeat;
    border: 4px solid;
    margin-left: auto;
    margin-right: auto;
}
.mobile-about
{
    margin-top: 2vh;
    /* height: 100%; */
}
.mobile-about img
{
    flex-grow: 2;
    height: auto;
    max-width: 80%;
    border: 4px solid;
    border-radius: 64px 0 64px 0;
}
.interface.lightMode .mobile-about img,
.interface.lightMode .about-container img,
.interface.lightMode .projectCard
{
    border-color: var(--black);
}
.interface.darkMode .mobile-about img,
.interface.darkMode .about-container img,
.interface.darkMode .projectCard
{
    border-color: var(--brown_100);
}

.mobile-about-info
{
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    height: fit-content;
}

.mobile-about button,
.about-container button
{
    font-family: var(--font1);
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    width: fit-content;

    border: none;
    border-radius: 16px 0 16px 0;
    padding: 16px;

    cursor: pointer;
    transition: 0.3s;
}
.interface.lightMode .mobile-about button,
.interface.lightMode .about-container button
{
    background-color: var(--black);
    color: var(--white);
}
.interface.darkMode .mobile-about button,
.interface.darkMode .about-container button
{
    background-color: var(--brown_100);
    color: var(--black);
}

.mobile-skills
{
    margin-top: 2vh;
    margin-bottom: 2vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;
}

.skill-label
{
    border: 2px solid;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 12px;
    cursor: default;
}
.interface.lightMode .skill-label
{
    border-color: var(--secondaryLightMode);
}
.interface.darkMode .skill-label
{
    border-color: var(--secondaryDarkMode);
}

.interface.lightMode .skill-label:hover
{
   background-color: var(--secondaryLightMode);
   color: var(--white); 
}
.interface.darkMode .skill-label:hover
{
    background-color: var(--secondaryDarkMode);
    color: var(--black); 
}

.mobile-contact
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 24px;
}
.mobile-contact img
{
    width: 90%;
}

/*
* Icons
*/
.mobile-overlay .cross-icon
{
    transform: scale(0);
    transform-origin: 50% 50%;
    transition: transform 0.3s;
}
.desktop-overlay .cross-icon
{
    position: absolute;
    top: -24px;
    right: -24px;
    transform-origin: 50% 50%;
    cursor: pointer;
    transition: transform 0.3s;
}
.desktop-overlay .cross-icon:hover
{
    transform: scale(1.2);
}

.next-icon,
.linkedin-icon,
.mail-icon
{
    cursor: pointer;
    transition: transform 0.3s;
}
.next-icon:hover,
.linkedin-icon:hover,
.mail-icon:hover
{
    transform: scale(1.2);
}
.next-icon:first-child
{
    transform: rotate(180deg);
}
.next-icon:first-child:hover
{
    transform: rotate(180deg) scale(1.2);
}

.interface.lightMode .cross-icon circle,
.interface.darkMode .next-icon circle
{
    fill: var(--brown_100);
}
.interface.darkMode .cross-icon circle,
.interface.lightMode .next-icon circle
{
    fill: var(--black);
}
.interface.lightMode .cross-icon path,
.interface.darkMode .next-icon path,
.interface.lightMode .linkedin-icon path,
.interface.lightMode .mail-icon path
{
    fill: var(--black);
}
.interface.darkMode .cross-icon path,
.interface.lightMode .next-icon path,
.interface.darkMode .linkedin-icon path,
.interface.darkMode .mail-icon path
{
    fill: var(--brown_100);
}

.cursor-extension {
    position: fixed;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transform-origin: bottom center;
    transition: transform 0.2s ease-out;
}

.cursor-extension span {
    display: block;
    border-radius: 16px 0 16px 0;
    padding: 16px;
    transform: scale(0);
    font-family: var(--font1);
    transition: 0.2s;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.interface.lightMode .cursor-extension span
{
    background-color: var(--black);
    color: var(--white);
}
.interface.darkMode .cursor-extension span
{
    background-color: var(--white);
    color: var(--black);
}

/*
* Responsive
*/
@media (min-width:641px)
{    
    .mobile-nav
    {
        border-radius: 32px 0 32px 0;
    }

    .mobile-about button,
    .mobile-nav button
    {
        font-size: 32px;
    }

    .projects-list
    {
        height: calc(100% - 10vh - 4px - 5vw);
    }

    .loader-text-title,
    h1
    {
        font-size: 48px;
    }

    h2
    {
        font-size: 36px;
    }

    .loader-text-p,
    h3,
    h5,
    p
    {
        font-size: 20px;
    }

    .skill-label
    {
        font-size: 16px;
    }

    .mobile-about img
    {
        height: 50%;
    }

    .mobile-about-info
    {
        width: 80%;
        margin: auto;
    }

    .switch
    {
        width: var(--tabletIcon);
        height: calc(var(--tabletIcon) / 1.8);  
    }
    .slider:before
    {
        height: calc(var(--tabletIcon) / 2.2);
        width: calc(var(--tabletIcon) / 2.2);
        left: calc(var(--tabletIcon) * 0.06);
        bottom: calc(var(--tabletIcon) * 0.05);
    }
    .switch input:checked + .slider:before
    {
        -webkit-transform: translateX(calc(var(--tabletIcon) * 0.42));
        -ms-transform: translateX(calc(var(--tabletIcon) * 0.42));
        transform: translateX(calc(var(--tabletIcon) * 0.42));
    }

    .loader svg
    {
        margin-top: 14vh;
    }
}

@media (min-width:1024px) 
{
    header
    {
        width: auto;
    }
    .point
    {
        display: block;
    }

    .mobile-nav,
    .mobile-overlay
    {
        display: none;
    }

    .desktop-overlay
    {
        display: flex;
    }

    .skill-label
    {
        font-size: 16px;
    }
}

@media (min-width:1724px) 
{    
    .loader-text-title,
    h1
    {
        font-size: 64px;
    }

    p,
    h5
    {
        font-size: 24px;
    }

    .point .label
    {
        font-size: 24px;
        padding: 16px 24px;
    }

    .desktop-overlay
    {
        width: 40vw;
    }
    .project-nav
    {
        width: 40vw;
    }
    .card
    {
        width: calc(40vw - 160px);
    }

    .switch
    {
        width: var(--desktopIcon);
        height: calc(var(--desktopIcon) / 1.8);  
    }
    .slider:before
    {
        height: calc(var(--desktopIcon) / 2.2);
        width: calc(var(--desktopIcon) / 2.2);
        left: calc(var(--desktopIcon) * 0.06);
        bottom: calc(var(--desktopIcon) * 0.05);
    }
    .switch input:checked + .slider:before
    {
        -webkit-transform: translateX(calc(var(--desktopIcon) * 0.42));
        -ms-transform: translateX(calc(var(--desktopIcon) * 0.42));
        transform: translateX(calc(var(--desktopIcon) * 0.42));
    }
}